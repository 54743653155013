import React from 'react';
import moment from 'moment';
import styles from './footer.module.scss';

export const Footer = () => {
  return (
    <footer id="crt-footer" className="crt-container-lg">
      <div className="crt-container">
        <div className="crt-container-sm clear-mrg text-center">
          <p>
            Copyrights &copy; Tomasz Libich, Front-End Developer, London - 2006-
            {moment().format('YYYY')}
          </p>
        </div>
      </div>
    </footer>
  );
};
