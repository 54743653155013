import React from 'react';
import styles from './header.module.scss';

export const TopNav = () => (
  <nav id="crt-nav-sm" className="crt-nav hidden-lg hidden-md">
    <ul className="clear-list">
      <li>
        <a href="index.html#about" data-tooltip="Home">
          <img
            className="avatar avatar-42"
            src="/images/tl.jpg"
            srcSet="/images/tl.jpg 2x"
            alt="Tomasz Libich"
          />
        </a>
      </li>
      <li>
        <a href="index.html#experience" data-tooltip="Experience">
          <span className="crt-icon crt-icon-experience"></span>
        </a>
      </li>
      <li>
        <a href="index.html#portfolio" data-tooltip="Portfolio">
          <span className="crt-icon crt-icon-portfolio"></span>
        </a>
      </li>
      <li>
        <a href="index.html#references" data-tooltip="References">
          <span className="crt-icon crt-icon-references"></span>
        </a>
      </li>
      <li>
        <a href="index.html#contact" data-tooltip="Contact">
          <span className="crt-icon crt-icon-contact"></span>
        </a>
      </li>
    </ul>
  </nav>
);
