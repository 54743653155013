import { Clients } from './clients';
import React from 'react';
import styles from './aside.module.scss';

export const Aside = props => {
  return (
    <div id="crt-side-box-wrap" className="crt-sticky">
      <div id="crt-side-box">
        <div className="crt-side-box-item">
          <div className="crt-card bg-primary text-center">
            <div className="crt-card-avatar">
              <img
                className="avatar avatar-195"
                src="/images/tl.jpg"
                srcSet="/images/tl.jpg 2x"
                width="195"
                height="195"
                alt=""
              />
            </div>
            <div className="crt-card-info">
              <h2 className="text-upper">Tomasz Libich</h2>

              <h1 className="text-muted">Front End Developer</h1>
              <ul className="crt-social clear-list">
                <li>
                  <a
                    href="http://www.linkedin.com/pub/tomasz-libich/19/1a5/838"
                    target="_blank"
                  >
                    <span className="crt-icon crt-icon-linkedin"></span>
                  </a>
                </li>
                <li>
                  <a href="https://github.com/tomaszlibich" target="_blank">
                    <span className="crt-icon crt-icon-github"></span>
                  </a>
                </li>
                <li>
                  <a href="http://twitter.com/#!/tomaszlibich" target="_blank">
                    <span className="crt-icon crt-icon-twitter"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="crt-side-box-btn">
            <a
              className="btn btn-default btn-lg btn-block btn-thin btn-upper"
              href="/pdf/tl_cv.pdf"
              target="_blank"
            >
              Download Resume
            </a>
          </div>
        </div>
        <Clients classes={['crt-side-box-item', 'aside-logos']} />
      </div>
    </div>
  );
};
