import React from 'react';
import styles from './header.module.scss';

import { TopNav } from './topnav';

export const Header = () => (
  <header id="crt-header">
    <div className="crt-head-inner crt-container">
      <div className="crt-container-sm">
        <div className="crt-head-row">
          <div id="crt-head-col2" className="crt-head-col text-right">
            <div className="crt-nav-container crt-container hidden-sm hidden-xs">
              <nav id="crt-main-nav">
                <ul className="clear-list">
                  <li>
                    <a href="index.html#experience" data-tooltip="Experience">
                      experience
                    </a>
                  </li>
                  <li>
                    <a href="index.html#portfolio" data-tooltip="Portfolio">
                      portfolio
                    </a>
                  </li>
                  <li>
                    <a href="index.html#references" data-tooltip="References">
                      references
                    </a>
                  </li>
                  <li>
                    <a href="index.html#contact" data-tooltip="Contact">
                      contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TopNav />
  </header>
);
