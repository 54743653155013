import React from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import styles from './default.module.scss';

import { Header } from '../common/header';
import { Aside } from '../common/aside';
import { SideNav } from '../common/sidenav';
import { Footer } from '../common/footer';

export default ({ children }) => (
  <div>
    <Helmet>
      <title>Front End Developer, London - Tomasz Libich</title>
    </Helmet>
    <div className="crt-wrapper">
      <Header />
      <div id="crt-container" className="crt-container">
        <Aside />
        <SideNav />

        <div className="crt-container-sm">{children}</div>
      </div>
    </div>

    <Footer />

    <svg
      id="crt-bg-shape-1"
      className="hidden-sm hidden-xs"
      height="519"
      width="758"
    >
      <polygon className="pol" points="0,455,693,352,173,0,92,0,0,71" />
    </svg>

    <svg
      id="crt-bg-shape-2"
      className="hidden-sm hidden-xs"
      height="536"
      width="633"
    >
      <polygon points="0,0,633,0,633,536" />
    </svg>
  </div>
);
